// Angular Imports
import { computed, inject } from '@angular/core'
import { HttpInterceptorFn, HttpHandlerFn, HttpRequest, HttpParams } from '@angular/common/http'
// Shared Imports
import { UiStore } from '@shared/store'
import { TranslocoLanguageKey, TranslocoLanguageName } from '@shared/models'

export const changeLanguageInterceptor: HttpInterceptorFn = (
	request: HttpRequest<unknown>,
	next: HttpHandlerFn,
) => {
	const uiStore = inject(UiStore)
	const language = computed(() => {
		switch (uiStore.language()) {
			case TranslocoLanguageKey.english:
				return TranslocoLanguageName.english
			case TranslocoLanguageKey.spanish:
				return TranslocoLanguageName.spanish
			default:
				return TranslocoLanguageName.english
		}
	})

	let cloneRequest: HttpRequest<unknown> = request

	if (request.headers.has('Language')) {
		if (request.method.toLowerCase() === 'get') {
			const params = new HttpParams({ fromObject: { language: language() } })
			cloneRequest = request.clone({ params })
		}
	}

	return next(cloneRequest)
}
