@defer (on viewport) {
	<main class="App" [class.Theme--dark]="theme() === UiTheme.dark">
		<!-- Environment -->
		<gld-environment-decorator />

		<!-- Navigation -->
		<gld-navbar />
		<gld-sidebar />

		<!-- Content -->
		<section #mainContent class="App-main">
			<router-outlet (activate)="onActivate()" />
		</section>
	</main>
} @placeholder (minimum 400ms) {
	<gld-loader loading background minHeight="100dvh" [theme]="theme()" />
}
