// Angular Imports
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Renderer2,
	RendererFactory2,
	computed,
	inject,
	viewChild,
} from '@angular/core'
import { RouterOutlet } from '@angular/router'
// Shared Imports
import { UiTheme } from '@shared/models'
import {
	SidebarComponent,
	EnvironmentDecoratorComponent,
	LoaderComponent,
	NavbarComponent,
} from '@shared/components'
import { UiStore } from '@shared/store'

const components = [
	NavbarComponent,
	SidebarComponent,
	EnvironmentDecoratorComponent,
	LoaderComponent,
]

@Component({
	standalone: true,
	selector: 'gld-root',
	imports: [RouterOutlet, ...components],
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
	readonly #rendererFactory = inject(RendererFactory2)
	readonly #uiStore = inject(UiStore)
	readonly UiTheme = UiTheme

	theme = computed(() => this.#uiStore.theme())
	mainContent = viewChild.required<ElementRef>('mainContent')
	#renderer: Renderer2

	constructor() {
		this.#renderer = this.#rendererFactory.createRenderer(null, null)
		// Automatically detect theme
		this.#renderer.listen('document', 'DOMContentLoaded', () => this.#detectTheme())
	}

	onActivate(): void {
		// Scroll to top on activate when navigate within the same component
		const mainContent = this.mainContent().nativeElement
		mainContent.scrollTop = 0
	}

	#detectTheme() {
		// Initially check if dark mode is enabled on system
		const darkModeIsOn = window.matchMedia('(prefers-color-scheme: dark)').matches
		// If dark mode is enabled then directly switch to the dark-theme
		this.#changeThemeDependsOnSystem(darkModeIsOn)
		// Watch for changes of the preference
		window
			.matchMedia('(prefers-color-scheme: dark)')
			.addEventListener('change', (event: MediaQueryListEvent) => {
				const darkModeIsOn = event.matches
				// If theme switch then change the theme
				this.#changeThemeDependsOnSystem(darkModeIsOn)
			})
	}

	#changeThemeDependsOnSystem(darkModeIsOn: boolean) {
		const theme = darkModeIsOn ? UiTheme.dark : UiTheme.light
		this.#uiStore.changeTheme(theme)
	}
}
