// Angular Imports
import { ApplicationConfig, isDevMode } from '@angular/core'
import {
	provideRouter,
	withComponentInputBinding,
	withViewTransitions,
	withInMemoryScrolling,
} from '@angular/router'
import { provideClientHydration } from '@angular/platform-browser'
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http'
import { provideAnimations } from '@angular/platform-browser/animations'
import { registerLocaleData } from '@angular/common'
import localeEs from '@angular/common/locales/es'
import localeEn from '@angular/common/locales/en'
// This Module Imports
import { routes } from './app.routes'
// Shared Imports
import { TranslocoLanguageKey } from '@shared/models'
import { TranslocoHttpLoader } from '@shared/services'
import { changeLanguageInterceptor } from '@shared/interceptors'
// Thirdparty Imports
import { provideTransloco } from '@jsverse/transloco'

// Configurations
registerLocaleData(localeEs, 'es')
registerLocaleData(localeEn, 'en')

export const appConfig: ApplicationConfig = {
	providers: [
		provideClientHydration(),
		provideRouter(
			routes,
			withComponentInputBinding(),
			withViewTransitions(),
			withInMemoryScrolling({ anchorScrolling: 'enabled', scrollPositionRestoration: 'top' }),
		),
		provideAnimations(),
		provideHttpClient(withFetch(), withInterceptors([changeLanguageInterceptor])),
		provideTransloco({
			config: {
				availableLangs: [TranslocoLanguageKey.english, TranslocoLanguageKey.spanish],
				defaultLang: TranslocoLanguageKey.english,
				fallbackLang: TranslocoLanguageKey.english,
				missingHandler: {
					// It will use the first language set in the `fallbackLang` property
					useFallbackTranslation: true,
					allowEmpty: true,
				},
				// Remove this option if your application doesn't support changing language in runtime.
				reRenderOnLangChange: true,
				prodMode: !isDevMode(),
				flatten: {
					aot: !isDevMode(),
				},
			},
			loader: TranslocoHttpLoader,
		}),
	],
}
