// Angular Imports
import type { Routes } from '@angular/router'

export const routes: Routes = [
	{
		path: '',
		redirectTo: 'me',
		pathMatch: 'full',
	},
	{
		path: 'me',
		loadChildren: () => import('./modules/me/me.routes').then((m) => m.ME_ROUTES),
	},
	{
		path: 'resources',
		loadChildren: () =>
			import('./modules/resources/resources.routes').then((m) => m.RESOURCES_ROUTES),
	},
	{
		path: '**',
		redirectTo: 'me',
		pathMatch: 'full',
	},
]
